.home {
  min-height: 95vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.home::before {
  content: "";
  background: var(--home) no-repeat center center/cover;
  background-attachment: fixed;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.home::after {
  content: "";
  background: rgba(0, 0, 0, 0.75);

  position: absolute;
  top: 0%;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.home__textContent h1 {
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 3.5rem;
  font-weight: 500;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 2s ease;
}

.home__textContent p {
  margin: 1.5rem;
  transform: translateY(100%);
  opacity: 0;

  transition: all 3s ease;
}

.homeText__appearEffect {
  transform: translateY(0) scaleY(1) !important;
  opacity: 1 !important;
}

.home__caretDown {
  font-size: xx-large;
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  opacity: 0;
  transition: all 2s ease;
}

.caretIcon__appearEffect {
  opacity: 1 !important;
}

@media (max-width: 650px) {
  .home__textContent h1 {
    font-size: 3rem;
  }
}

@media (max-width: 400px) {
  .home__textContent h1 {
    font-size: 2rem;
  }
}
