.skills {
  background-color: rgb(236, 236, 236);
  min-height: 90vh;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills__description {
  margin: 0 3rem;
  text-align: center;
}

.skills__section {
  display: flex;
  flex-direction: column;
  margin: 3rem;
}

.skills__section > .section__header {
  font-size: 1.3rem;
  margin: 0rem 2rem;
  margin-top: 1rem;
  text-align: start;
}

.skills__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  width: 100%;

  opacity: 0;
  transition: all 2s ease;
}

.skills__appearEffect {
  opacity: 1;
}

@media (max-width: 768px) {
  .skills__section {
    margin: 1.5rem;
  }
}
