.about {
  min-height: 90vh;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 60%;
  margin: 0.5rem auto;
}

.about__profilePhoto {
  height: 10rem;
  width: 10rem;
  object-fit: cover;
  border-radius: 50%;
  margin: 1rem 0;

  opacity: 0;
  transition: all 2s ease;
}

.about__profilePhoto::before {
  content: "";
  background: url("/public/img/avatar.png") no-repeat center center/contain;

  position: absolute;
  top: 0;
  left: 0;
  height: 10rem;
  width: 10rem;
  z-index: -1;
}

.about__appearEffect {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.about__description {
  margin: 1rem;
  max-width: 450px;
  text-align: center;
  font-family: "Heebo", sans-serif;

  transform: translateY(50px);
  opacity: 0;
  transition: all 2s ease;
}

.about__description p {
  text-align: justify;
  text-align-last: auto;
  margin-bottom: 0.5rem;
}

.about__description > p:first-child {
  text-align: center;
}

.about__hobbies {
  margin: 1rem;
  margin-top: 0;
  text-align: justify;
  text-align-last: auto;
  max-width: 450px;

  transform: translateY(50px);
  opacity: 0;
  transition: all 2s ease;
}

@media (max-width: 1000px) {
  .about {
    width: 70%;
  }
}

@media (max-width: 860px) {
  .about {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .about {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .about {
    width: 100%;
  }
}
