.app {
  font-family: "Heebo";
}

.section__header {
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 1.8rem;
  margin: 2rem 0;
  text-align: center;
}
